.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 0;

    color: rgba(1, 1, 1, 0.26);
}

.value {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 20px;

    border: 1px solid var(--uiPrimaryColor);
    border-radius: 4px;

    color: var(--uiPrimaryColor);
    font-size: 12px;
    text-align: center;
}

:global(.darcula) .value {
    color: rgba(255, 255, 255, 0.8);

    background: #2b3136;
}

.invalidInput {
    border: 1px solid #f44e72;

    color: var(--cancelColor);
}

.invalidInput:focus {
    outline: 1px solid #f44e72;
}
